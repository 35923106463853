import React, { Component } from "react";
import App from "./App";
import Footer from "./Footer";
import DetailWorkCard from "./DetailWorkCard";
import laptop from "../assets/images/laptop.jpg";
import { Container, Row, Col } from "reactstrap";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  Button
} from "reactstrap";

class DetailWork extends Component {
  constructor(props) {
    super(props);
    this.state = { detail: false };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ detail: true });
  }
  componentDidUpdate() {
    const { history } = this.props;

    if (!this.props.location.title) {
      history.push("/");
    }
  }
  render() {
    const { detail } = this.state;

    return (
      <div>
        <App detail={detail} />
        <Container fluid style={{ marginBottom: "50px", marginTop: "10vh" }}>
          <Row className="justify-content-center mt-5">
            {this.props.location.title ? (
              <Col className="mt-3">
                <DetailWorkCard
                  history={this.props.history}
                  title={this.props.location.title}
                  subtitle={this.props.location.subtitle}
                  noi={this.props.location.noi}
                  projectPosition={this.props.location.projectPosition}
                />
              </Col>
            ) : (
              <Col className="mt-3" sm="12" md={{ size: 6 }}>
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={laptop}
                    alt="nothing here go back to see detail"
                  />
                  <CardBody>
                    <CardTitle>No detail here !</CardTitle>

                    <CardText>
                      Please choose de picture to see the detail
                    </CardText>
                    <Button
                      onClick={() => this.props.history.push("/")}
                      className="btn btn-dark btn-block"
                    >
                      Go back
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>

        <Footer />
      </div>
    );
  }
}

export default DetailWork;
