import React, { Component } from "react";
import { Container, Row, Col, Card, CardImg } from "reactstrap";
import App from "./App";
import Footer from "./Footer";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = { about: false };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ about: true });
  }

  render() {
    const { about } = this.state;
    return (
      <div>
        <App about={about} />
        <Container style={{ marginBottom: "200px", marginTop: "60px" }}>
          <Row>
            <Col className="text-center mt-5">
              <h4 className="text-light text-center">About me</h4>
            </Col>
          </Row>

          <Row>
            <Col
              xs={{ size: 10, offset: 1 }}
              sm={{ size: 4, offset: 1 }}
              className="mt-4 text-center"
            >
              <Card
                style={{ backgroundColor: "transparent", border: "none" }}
                className="text-center"
              >
                <CardImg
                  style={{ boxShadow: "0px 0px 20px 0px #000" }}
                  top
                  width="100%"
                  src="https://d3iqoon0y6dlzh.cloudfront.net/profil.jpg"
                  alt="julien denamur"
                />
              </Card>
            </Col>
            <Col
              xs="12"
              sm={{ size: 7 }}
              className="mt-4 text-light text-center align-items-center"
            >
              <div className="flex-column text-left">
                <h6 className="mb-4">
                  <span className="h5">
                    <ins>Skills</ins>
                  </span>{" "}
                  : Modelling, Photography
                </h6>

                <h6 className="mb-4">
                  {" "}
                  <span className="h5">
                    <ins>Softwares</ins>
                  </span>{" "}
                  : Maya, Mari, Photoshop
                </h6>

                <h6 className="mb-4">
                  <span className="h5">
                    <ins>Languages</ins>
                  </span>{" "}
                  : French, English, Korean, German
                </h6>

                <h6>
                  <span className="h5">
                    <ins>Hobbies</ins>
                  </span>{" "}
                  : Cooking, Traveling
                </h6>
                <p className="mt-4">
                  {" "}
                  I am a French hard surface modeller. I am passionate about
                  recreating real life in 3D as realistically as possible. Years
                  of consuming visual media have taught me the importance of
                  infusing story and character in my creations. Photography has
                  helped me sharpen my story-telling and composition skills.
                </p>
              </div>
            </Col>
          </Row>
          <Footer />
        </Container>
      </div>
    );
  }
}

export default About;
