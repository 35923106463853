import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class Demo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Row>
        <Col className="text-center mt-5">
          <h4 className="mb-4 text-light">Demo Reel</h4>
          <iframe
            style={{ width: '100%' }}
            height="400px"
            title="video demo 1"
            src="https://player.vimeo.com/video/252568002"
            frameBorder="0"
            webkitallowfullscreen={true.toString()}
            mozallowfullscreen={true.toString()}
            allowFullScreen
          />
        </Col>
      </Row>
    );
  }
}

export default Demo;
