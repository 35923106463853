import React from 'react';
import App from './App';
import Footer from './Footer';
import { Container, Row, Col } from 'reactstrap';
import lifecycle from 'react-pure-lifecycle';

const methods = {
  componentDidMount() {
    window.scrollTo(0, 0);
  },
};

const Professional = () => {
  return (
    <div>
      <App />
      <Container fluid style={{ marginBottom: '400px', marginTop: '1vh' }}>
        <Row>
          <Col className="text-center mt-5">
            <h4 className="text-light">Professional Work</h4>
          </Col>
        </Row>
        <Row>
          <Col className="text-center text-light mt-3">
            <h4>As a student, I currently do not have any professional work to show.</h4>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
};
export default lifecycle(methods)(Professional);
