import React, { Component } from 'react';
import validator from 'validator';
import App from './App';
import Footer from './Footer';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import ReCAPTCHA from 'react-recaptcha';
import { Form, FormGroup, Label, Input } from 'reactstrap';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      messageSend: false,
      responseName: '',
      isVerify: false,
      isLoaded: false,
      contact: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.recaptchaLoad = this.recaptchaLoad.bind(this);
  }
  recaptchaLoad() {
    this.setState({ isLoaded: true });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ contact: true });
  }
  componentWillUnmount() {
    this.setState({ contact: false });
  }

  verifyCallback(response) {
    if (response) {
      this.setState({ isVerify: true });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { name, email, message, isVerify } = this.state;
    const messageSide = validator.isLength(message, { min: 1, max: 500 });

    if (isVerify && messageSide) {
      await axios
        .post('/api/form', {
          name,
          email,
          message
        })
        .then(res => {
          this.setState({ responseName: res.data.name });
        });

      this.setState({
        messageSend: true
      });
    } else {
      if (!isVerify && messageSide) {
        alert('you have to veriry if you are a human');
      } else if (!messageSide && isVerify) {
        alert('Your message is too long');
      }
    }
  }

  render() {
    const { messageSend, isVerify, isLoaded, contact } = this.state;

    return (
      <div>
        <App contact={contact} />
        <Container style={{ marginBottom: '200px', marginTop: '60px' }}>
          <Row>
            <Col className="text-center mt-5">
              <h4 className="text-light">Contact</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              {/* <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label className="text-light" for="Name">
                    Name
                  </Label>
                  <Input
                    onChange={this.handleChange}
                    className="form-control"
                    type="text"
                    name="name"
                    id="Name"
                    placeholder="Enter your name here"
                    required
                    autoFocus
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="text-light" for="Email">
                    Email
                  </Label>
                  <Input
                    onChange={this.handleChange}
                    className="form-control"
                    type="email"
                    name="email"
                    id="Email"
                    placeholder="Enter your email here"
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label className="text-light" for="Message">
                    Your message
                  </Label>
                  <textarea
                    onChange={this.handleChange}
                    className="form-control"
                    rows="4"
                    type="text-area"
                    name="message"
                    id="Message"
                    placeholder="Enter your message here"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col md={{ size: 6, offset: 4 }}>
                      <ReCAPTCHA
                        sitekey="6LeW1HwUAAAAAOfPQumymIrmZRLs6wMxOIZSFapR"
                        render="explicit"
                        onloadCallback={this.recaptchaLoad}
                        verifyCallback={this.verifyCallback}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <button
                  className={
                    isVerify & isLoaded
                      ? 'btn btn-success btn-block btn-lg'
                      : 'btn btn-success btn-block btn-lg disabled'
                  }
                >
                  Submit
                </button>
                <Row>
                  <Col className="text-center mt-5">
                    <h6 className="text-light">
                      Use this form or send me an email directly at contactjuliendenamur@gmail.com
                    </h6>
                  </Col>
                </Row>
              </Form> */}
              <Row>
                <Col className="text-center mt-5">
                  <h6 className="text-light">
                    Please contact me via <a
                      style={{ textDecoration: 'underline', color: '#fff' }}
                      href="https://www.linkedin.com/in/juliendenamur/"
                    >
                      LinkedIn
                    </a>{' '}or send me an email at{' '}
                    <a
                      style={{ textDecoration: 'underline', color: '#fff' }}
                      href="mailto:contactjuliendenamur@gmail.com"
                    >
                      contactjuliendenamur@gmail.com
                    </a>
                    .
                  </h6>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        {messageSend && <Redirect to={{ pathname: '/', name: this.state.responseName }} />}
        <Footer />
      </div>
    );
  }
}

export default Contact;
