import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import PersonalCard from "./PersonalCard";

import axios from "axios";

class Personal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: [],
      subtitle: [],
      picturesByProject: {
        projectOne: 4,
        projectTwo: 4,
        projectThree: 4,
        projectFour: 4
      }
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    axios.get("https://d3iqoon0y6dlzh.cloudfront.net/julien.json").then(res => {
      const { title, Subtitle, picturesByProject } = res.data;
      if (picturesByProject) {
        this.setState({ title, subtitle: Subtitle, picturesByProject });
      } else {
        this.setState({ title, subtitle: Subtitle });
      }
    });
  }

  render() {
    const { title, subtitle, picturesByProject } = this.state;
    const { history } = this.props;

    return (
      <div>
        <Row>
          <Col className="text-center mt-5">
            <h4 className="text-light">Personal Work</h4>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            <h4 className="text-light">
              Here's a selection of my personal work. Press the images to see
              more details.
            </h4>
          </Col>
        </Row>

        {picturesByProject && (
          <Row className="mr-5 ml-5 mb-5 mt-0">
            <Col xs="12" sm="6" md="6" className="mb-0 p-0">
              {title[0] && (
                <PersonalCard
                  title={title[0].titleOne}
                  subtitle={subtitle[0].SubTitleOne}
                  history={history}
                  projectPosition={1}
                  noi={picturesByProject.projectOne}
                  image1="https://d3iqoon0y6dlzh.cloudfront.net/project-1/project-1-img-1.jpg"
                />
              )}
            </Col>
            <Col xs="12" sm="6" md="6" className="mb-0 p-0">
              {title[1] && (
                <PersonalCard
                  title={title[1].titleTwo}
                  subtitle={subtitle[1].SubTitleTwo}
                  subtitle1="Building Breakdowns"
                  subtitle2="Environment Props"
                  history={history}
                  projectPosition={2}
                  noi={picturesByProject.projectTwo}
                  image1="https://d3iqoon0y6dlzh.cloudfront.net/project-2/project-2-img-1.jpg"
                />
              )}
            </Col>
            <Col xs="12" sm="6" md="6" className="mb-4 p-0">
              {title[2] && (
                <PersonalCard
                  title={title[2].titleThree}
                  subtitle={subtitle[2].SubTitleThree}
                  history={history}
                  projectPosition={3}
                  noi={picturesByProject.projectThree}
                  image1="https://d3iqoon0y6dlzh.cloudfront.net/project-3/project-3-img-1.jpg"
                />
              )}
            </Col>
            <Col xs="12" sm="6" md="6" className="mb-4 p-0">
              {title[3] && (
                <PersonalCard
                  title={title[3].titleFour}
                  subtitle={subtitle[3].SubTitleFour}
                  history={history}
                  projectPosition={4}
                  noi={picturesByProject.projectFour}
                  image1="https://d3iqoon0y6dlzh.cloudfront.net/project-4/project-4-img-1.jpg"
                />
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default Personal;
