import React, { Component } from 'react';
import App from './App';
import { Container, Row, Col } from 'reactstrap';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Personal from './Personal';
import Professional from './Professional';
import ScrollableAnchor from 'react-scrollable-anchor';
import { configureAnchors } from 'react-scrollable-anchor';
import Demo from './Demo';

// Offset all anchors by -60 to account for a fixed header
// and scroll more quickly than the default 400ms
configureAnchors({ offset: -60, scrollDuration: 1000 });

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { showBanner: false, messageSendName: false };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.location.name) {
      this.notify();
    }
  }

  notify() {
    toast.success(`Thank you ${this.props.location.name} your message has been sent ! `, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  render() {
    return (
      <div>
        <App />
        <Container fluid style={{ marginTop: '110px' }}>
          <Row>
            <Col className="text-center">
              <ScrollableAnchor id={'home'}>
                <Demo />
              </ScrollableAnchor>
            </Col>
          </Row>
          <Row>
            <Col>
              <ScrollableAnchor id={'personal'}>
                <Personal />
              </ScrollableAnchor>
            </Col>
          </Row>
          <Row>
            <Col>
              <ScrollableAnchor id={'professional'}>
                <Professional />
              </ScrollableAnchor>
            </Col>
          </Row>

          <Footer />
        </Container>

        <ToastContainer />
      </div>
    );
  }
}

export default Home;
