import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [hover, setHover] = useState(false);
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    if (
      document.cookie.split(';').filter(item => item.trim().startsWith('julienAgreeCookies='))
        .length
    ) {
      setShowBanner(false);
    }
  });

  const cookieConsent = () => {
    setShowBanner(false);
    document.cookie = 'julienAgreeCookies=true;max-age=60*60*24*365';
  };
  const bannerContainer = {
    backgroundColor: '#fff',
    opacity: '0.8',
    color: '#000',
    position: 'fixed',
    left: '0px',
    bottom: '12px',
    width: '100%',
    padding: '10px 30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const buttonStyle = {
    padding: '10px',
    color: '#fff',
    backgroundColor: '#000',
    fontWeight: '600',
    cursor: hover && 'pointer',
  };
  if (!showBanner) {
    return null;
  }
  return (
    <div style={bannerContainer}>
      <div>By continuing to browse this website, you accept the use of cookies.</div>
      <div
        onMouseEnter={() => setHover(!hover)}
        onMouseLeave={() => setHover(!hover)}
        style={buttonStyle}
        onClick={cookieConsent}
      >
        OK
      </div>
    </div>
  );
};

export default CookieBanner;
