import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class PersonalCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      history,
      title,
      subtitle,
      noi,
      projectPosition,
      image1
    } = this.props;

    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() =>
          history.push({
            pathname: "/detail",
            title,
            subtitle,
            noi,
            projectPosition
          })
        }
      >
        <div className="containerForHover">
          <div className="viewNotHover">
            <div className="carousel-caption pb-0">
              <h4>{title}</h4>
            </div>
            <img width="100%" src={image1} alt="3D project" />
          </div>
          <div className="viewHover">
            <div className="text-light">
              <h1>View</h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PersonalCard);
