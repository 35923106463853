import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './assets/styles/styles.css';
import './assets/styles/burger.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
import Professional from './components/Professional';
import Personal from './components/Personal';
import Contact from './components/Contact';
import DetailWork from './components/DetailWork';
import About from './components/About';
import ReactGA from 'react-ga';
import CookieBanner from './components/CookieBanner';

ReactGA.initialize('UA-155225431-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <div>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/professional" component={Professional} />
        <Route path="/personal" component={Personal} />
        <Route path="/detail" component={DetailWork} />
        <Route path="/contact" component={Contact} />
        <Route path="/about" component={About} />
      </Switch>
    </BrowserRouter>
    <CookieBanner />
  </div>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
