import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Collapse, Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { Helmet } from 'react-helmet';
import logo from '../assets/images/logoJulien.png';
import artStation from '../assets/images/logoArtstation.png';
import insta from '../assets/images/logoInstagram.png';
import linkedin from '../assets/images/logoLinkedIn.png';

class App extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { isOpen: false, burger: false };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
      burger: !this.state.burger,
    });
  }
  render() {
    const { history, contact, about, detail } = this.props;
    const { burger } = this.state;

    return (
      <div className="fixed-top">
        <Helmet>
          <style>{'body { background-color: #000; }'}</style>
        </Helmet>
        <Navbar
          style={{ backgroundColor: '#000', borderBottom: '1px solid #FFF', padding: 0 }}
          dark
          expand="lg"
        >
          <NavbarBrand className="text-start p-0 mr-5">
            <div
              style={{ minWidth: '220px' }}
              onClick={() => history.push('/')}
              className="titleDesktop text-light"
            >
              <img
                style={{ float: 'left', cursor: 'pointer' }}
                className="mr-2 ml-2 mb-0"
                height="80vh"
                src={logo}
                alt="logo"
              />
              <div style={{ paddingTop: '19px', letterSpacing: '0.4px' }}>
                <h6>Julien Denamur</h6>
                <h6>Hard Surface Modeller</h6>
              </div>
            </div>
          </NavbarBrand>
          <div onClick={() => history.push('/')} className="titleMobile text-center text-light">
            <div style={{ letterSpacing: '0.4px' }}>
              <h6>Julien Denamur</h6>
              <h6>Hard Surface Modeller</h6>
            </div>
          </div>
          <div className="titleMobile">
            <button
              className={
                burger ? 'hamburger hamburger--collapse is-active' : 'hamburger hamburger--collapse'
              }
              type="button"
              onClick={this.toggle}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner " />
              </span>
            </button>
          </div>
          <Collapse className="justify-content-around pt-3" isOpen={this.state.isOpen} navbar>
            <Nav className="nav-fill text-center" navbar>
              <NavItem>
                {contact || about || detail ? (
                  <Link className="h6 text-white navbar-expand-md mr-5 nav-link" to="/">
                    Work
                  </Link>
                ) : (
                  <a className="h6 text-white navbar-expand-md mr-5 nav-link" href="#home">
                    Demo Reel
                  </a>
                )}
              </NavItem>
              <NavItem>
                {contact || about || detail ? null : (
                  <a className="h6 text-white navbar-expand-md mr-5 nav-link" href="#personal">
                    Personal
                  </a>
                )}
              </NavItem>{' '}
              <NavItem>
                {contact || about || detail ? null : (
                  <a className="h6 text-white navbar-expand-md mr-5 nav-link" href="#professional">
                    Professional
                  </a>
                )}
              </NavItem>
              <NavItem>
                <Link className="h6 text-white navbar-expand-md mr-5 nav-link" to="/about">
                  About
                </Link>
              </NavItem>
              <NavItem>
                <Link className="h6 text-white navbar-expand-md mr-5 nav-link" to="/contact">
                  Contact
                </Link>
              </NavItem>
              <NavItem style={{ minWidth: '220px' }} className="d-flex justify-content-around mr-5">
                <a
                  href="https://www.artstation.com/juliendenamur"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="pb-2" height="45vh" src={artStation} alt="artstation" />
                </a>
                <a
                  href="https://www.instagram.com/julien_denamur/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="pb-2" height="45vh" src={insta} alt="instagram" />
                </a>
                <a
                  href="https://www.linkedin.com/in/juliendenamur/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className=" pb-2" height="45vh" src={linkedin} alt="instagram" />
                </a>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(App);
