import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import Paper from "@material-ui/core/Paper";
import Fade from "react-reveal/Fade";

class DetailWorkCard extends Component {
  constructor(props) {
    super(props);
    this.state = { totalOfImages: [] };
  }

  componentDidMount() {
    const { noi, projectPosition } = this.props;
    const { totalOfImages } = this.state;

    for (let i = 1; i <= noi; i++) {
      totalOfImages.push(
        `https://d3iqoon0y6dlzh.cloudfront.net/project-${projectPosition}/project-${projectPosition}-img-${i}.jpg`
      );
    }
  }

  render() {
    const { title, subtitle, history } = this.props;
    const { totalOfImages } = this.state;
    return (
      <div>
        <Row>
          <Col className="text-center text-light mt-5">
            <Fade top>
              <h4 className="mb-4">{title}</h4>
              <h6 className="mb-4">{subtitle}</h6>
            </Fade>
          </Col>
        </Row>
        <Row>
          {totalOfImages.map(img => {
            return img ? (
              <Col
                key={img}
                xs="12"
                md={{ size: 8, offset: 2 }}
                className="mb-3"
              >
                <Paper elevation={24}>
                  <img width="100%" src={img} alt="detail work" />
                </Paper>
              </Col>
            ) : null;
          })}
        </Row>
        <Row>
          <Col xs="12" md={{ size: 8, offset: 2 }} className="mt-5">
            <Button
              onClick={() => history.push("/")}
              className="btn btn-dark btn-block"
            >
              Go back
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DetailWorkCard;
